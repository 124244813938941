<template>
  <step-lesson-layout class="module2-part1-lesson1"
    :title="$t('module2.part1.lesson.title')" :title-annotation="$t('module2.part1.lesson.titleAnnotation')">
    <template v-slot:content>
      <!-- Intro Interaction PNCEE / Demandeur -->
      <div class="app-text-block">
        <p class="title">
          {{ $t('module2.part1.lesson.interactionBlock.title') }}
        </p>
        <div class="intro-infography-block">
          <div class="illu" v-html="mq.tabletMinus ? require('@/assets/module2/part1/interaction1-mobile.svg?inline') : require('@/assets/module2/part1/interaction1-desktop.svg?inline')"></div>
        </div>
      </div>

      <!-- BLOCK 1 - Acteurs -->
      <div class="actors-block app-text-block">
        <p class="title">
          {{ $t('module2.part1.lesson.block1.title') }}
        </p>

        <!-- Grille des Acteurs -->
        <div class="groups-wrapper">
          <div class="actors-group" v-for="group in actorsGroups" :key="group.name" :class="group.name">
            <p class="group-title">{{ group.title }}</p>
            <div class="actor" v-for="actor in group.actors" :key="actor.name">
              <div class="image-wrapper">
                <img :src="actor.image" :alt="actor.name">
              </div>
              <p class="content">
                <span class="label" v-html="actor.label"></span> <span>{{ actor.content }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- END BLOCK -->
      <app-button-layout @click="$emit('next-tab')">
        {{ $t('global.navigation.resume') }}
      </app-button-layout>
    </template>
  </step-lesson-layout>
</template>

<script>
import StepLessonLayout from '@/components/training/StepLessonLayout'
import AppButtonLayout from '@/components/buttons/AppButtonLayout'

export default {
  name: 'Module2Part1Lesson',
  components: { AppButtonLayout, StepLessonLayout },
  inject: ['mq'],
  data () {
    return {
      actorsGroups: [
        {
          name: 'group1',
          title: this.$t('module2.part1.lesson.block1.actors.title1'),
          actors: [
            {
              name: 'pncee',
              label: this.$t('module2.part1.lesson.block1.actors.pnceeLabel'),
              content: this.$t('module2.part1.lesson.block1.actors.pnceeContent'),
              image: require('@/assets/module2/part1/illu-pncee.svg')
            }
          ]
        },
        {
          name: 'group2',
          title: this.$t('module2.part1.lesson.block1.actors.title2'),
          actors: [
            {
              name: 'oblige',
              label: this.$t('module2.part1.lesson.block1.actors.obligeLabel'),
              content: this.$t('module2.part1.lesson.block1.actors.obligeContent'),
              image: require('@/assets/module2/part1/illu-oblige.svg')
            },
            {
              name: 'delegataire',
              label: this.$t('module2.part1.lesson.block1.actors.delegataireLabel'),
              content: this.$t('module2.part1.lesson.block1.actors.delegataireContent'),
              image: require('@/assets/module2/part1/illu-delegataire.svg')
            },
            {
              name: 'eligible',
              label: this.$t('module2.part1.lesson.block1.actors.eligibleLabel'),
              content: this.$t('module2.part1.lesson.block1.actors.eligibleContent'),
              image: require('@/assets/module2/part1/illu-elligible.svg')
            }
          ]
        },
        {
          name: 'group3',
          title: this.$t('module2.part1.lesson.block1.actors.title3'),
          actors: [
            {
              name: 'mo',
              label: this.$t('module2.part1.lesson.block1.actors.maitreOuvrageLabel'),
              content: this.$t('module2.part1.lesson.block1.actors.maitreOuvrageContent'),
              image: require('@/assets/module2/part1/illu-maitre-ouvrage.svg')
            }
          ]
        },
        {
          name: 'group4',
          title: this.$t('module2.part1.lesson.block1.actors.title4'),
          actors: [
            {
              name: 'pro',
              label: this.$t('module2.part1.lesson.block1.actors.proLabel'),
              content: this.$t('module2.part1.lesson.block1.actors.proContent'),
              image: require('@/assets/module2/part1/illu-pro.svg')
            }
          ]
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.module2-part1-lesson1 {
  .intro-infography-block {
    background-color: $c-secondary;
    padding: $space-m;
    border-radius: $radius;
  }
  .actors-block {
    padding: 0 $space-s;
    .groups-wrapper {
      display: grid;
      gap: $space-m;
      .actors-group {
        background-color: #fff;
        border-radius: $radius;
        padding: $space-s;
        .group-title {
          text-transform: uppercase;
          color: $c-secondary;
          font-weight: $fw-l;
          margin-bottom: $space-s;
        }
        .actor + .actor {
          margin-top: $space-m;
        }
        .actor {
          display: flex;
          align-items: center;
          .image-wrapper {
            min-width: 60px;
            display: flex;
            justify-content: center;
            margin-right: $space-m;
            img {
              max-width: 100%;
            }
          }
          .content {
            font-size: $fz-s;
            .label {
              color: $c-secondary;
              font-weight: $fw-l;
            }
          }
        }
      }
    }
  }
  .app-button-layout {
    margin: $space-ml auto;
  }
}

@media (min-width: $bp-tablet) {
  .module2-part1-lesson1 {
    .actors-block {
      .groups-wrapper {
        grid-template-columns: repeat(2, 1fr);
        .group2 {
          grid-row-start: span 3;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.module2-part1-lesson1 {
  .intro-infography-block {
    .illu {
      svg {
        width: 100%;
        height: auto;
      }
    }
  }
}
</style>
